.arrowButton {
  margin-top: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
}

.panel {
  background-color: #32414f;
  color: white;
  min-height: 110vh;
  padding: 20vmin 5vmin;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  padding: 0;
}

.bgPicture {
  object-fit: cover;
  height: 110vh;
}

.video {
  object-fit: fill;
  height: 110vh;
  position: absolute;
  z-index: 0;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2;
  background: rgba(0, 0, 0, 0.6);
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
}

.header {
  animation: fadeIn 2s 0.5s backwards;
  text-align: center;
  max-width: 45rem;
  padding: 20vmin 5vmin;
  margin: 0 auto;
}

.header h1 {
  font-size: clamp(3rem, 16vmin, 4rem);

  font-weight: 700;
  line-height: 95%;
  margin-bottom: 2rem;
}

.header p {
  font-size: calc(2vmin + 1.5rem);
  line-height: 138%;
  margin-bottom: 1rem;
}

.topplinje {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 1rem;
  display: flex;
}

.left {
  justify-content: flex-start;
}

.right {
  justify-content: flex-end;
}

.topplinje svg {
  width: 4rem;
  fill: white;
}

.bunnlinje {
  position: absolute;
  bottom: 10vh;
  left: 0;
  width: 100%;
  padding: 1rem;
  display: flex;
  justify-content: "flext-start";
}

.bunnlinje svg {
  width: 4rem;
  fill: white;
}
