.panelWrapper {
  --content-max-width: min(32.5rem, 100%);
  height: 110vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.videoWrapper {
  width: calc(var(--content-max-width) * 1.15);
  max-width: 100vw;
  height: 100vh;
}

@media (max-width: 768px) {
  .videoWrapper {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
